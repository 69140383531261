<template>
  <div style="height: inherit">

    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <!--              <feather-icon-->
              <!--                icon="MenuIcon"-->
              <!--                class="d-block d-lg-none"-->
              <!--                size="21"-->
              <!--                @click="mqShallShowLeftSidebar = true"-->
              <!--              />-->

            </div>

            <div class="view-options d-flex">
              <!-- Per Page -->
              <!--              <b-col>-->
              <!--                <label>Show</label>-->
              <!--                <v-select-->
              <!--                  v-model="filters.perPage"-->
              <!--                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
              <!--                  :options="filterOptions.perPage"-->
              <!--                  :clearable="false"-->
              <!--                  class="per-page-selector d-inline-block mx-50"-->
              <!--                />-->
              <!--                <label>entries</label>-->
              <!--              </b-col>-->
              <!-- Sort Button -->
              <!--              <b-dropdown-->
              <!--                v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
              <!--                :text="$t(sortBy.text)"-->
              <!--                right-->
              <!--                variant="outline-primary"-->
              <!--              >-->
              <!--                <b-dropdown-item-->
              <!--                  v-for="sortOption in sortByOptions"-->
              <!--                  :key="sortOption.value"-->
              <!--                  @click="sortBy=sortOption"-->
              <!--                >-->
              <!--                  {{ $t(sortOption.text) }}-->
              <!--                </b-dropdown-item>-->
              <!--              </b-dropdown>-->

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <b-overlay
      :show="submittingFetch"
      rounded
      opacity="0.6"
      spinner-medium
      spinner-variant="primary"
    >
      <div class="body-content-overlay" />

      <!-- Searchbar -->
      <div class="ecommerce-searchbar mt-1">
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <div class="search-results">
                {{ $tc("requests found", totalPoints) }}
                <span v-if="!totalPoints"><b-link :to="{ name: 'pages-account-setting', query: { tab: 'services' } }">{{ $t('Please check if your offers are published') }}</b-link></span>
              </div>
              <b-input-group>

                <b-form-input
                  v-model="filters.q"
                  :placeholder="$t('Search')"
                  class="search-point"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="SearchIcon"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <!-- Points -->
      <section :class="itemView">
        <b-card
          v-for="point in points"
          :key="point.id"
          class="ecommerce-card no-image"
        >
          <b-card-text>
            <div class="position-absolute statuses">
              <b-badge
                v-if="point.receiver && point.receiver.id===user.id"
                variant="light-warning"
              >
                <small class="text-nowrap">
                  {{ $t('point.direct_request') }}
                  <feather-icon
                    icon="BookmarkIcon"
                  />
                </small>
              </b-badge>
              <b-badge
                :variant="`light-${getPostStatusProperty(point.post_status, 'class')}`"
              >
                <small>
                  {{ $t(point.post_status) }}
                  <feather-icon
                    :icon="getPostStatusProperty(point.post_status, 'icon')"
                  />
                </small>
              </b-badge>
            </div>
          </b-card-text>

          <router-link
            v-if="itemView==='grid-view'"
            class="item-img text-center"
            :to="{name: 'apps-search-service-request-details', params: { id: point.id }}"
          >
            <b-img-lazy
              :alt="`${point.name}-${point.id}`"
              fluid
              class="card-img-top"
              :src="getFirstGalleryImage(point)"
              @error.native="$event.target.src=defaultImage"
            />
          </router-link>

          <!-- Product Details -->
          <b-card-text
            class="link"
            @click="$router.push({name: 'apps-search-service-request-details', params: { id: point.id }})"
          >
            <b-card-text class="text-left">
              <b-badge
                pill
                :variant="getOwnerProperty(point).class"
              >
                <feather-icon
                  :icon="getOwnerProperty(point).icon"
                />
                #
                <small>
                  {{ point.id }}
                </small>
              </b-badge>
              <b-badge
                pill
                variant="light-secondary"
              >
                <feather-icon
                  :icon="getPostStatusProperty(point.post_status, 'icon_visibility')"
                  class="mr-25"
                />
                <span>
                  {{ point.number_of_views }}
                </span>
              </b-badge>
              <b-badge
                pill
                variant="light-secondary"
              >
                <small class="text-nowrap">
                  <feather-icon
                    v-if="point.create_date!==point.update_date"
                    icon="Edit3Icon"
                    class="mr-25"
                  />
                  <feather-icon
                    v-else
                    icon="Edit2Icon"
                    class="mr-25"
                  />{{ point.update_date | formatDateTime() }}
                </small>

                <small
                  v-if="point.close_date"
                  class="text-wrap"
                >
                  {{ $t(point.post_status) }} | {{ point.close_date | formatDateTime() }}
                </small>
              </b-badge>
              <b-badge
                pill
                variant="light-secondary"
              >
                <feather-icon
                  icon="ThumbsUpIcon"
                  class="mr-25"
                />
                <span>
                  {{ point.events.length.toString() }}
                </span>
              </b-badge>
              <b-badge
                pill
                variant="light-secondary"
              >
                <small>
                  {{ getTypeTitle(point.point_type.slug) }}
                </small>
              </b-badge>
            </b-card-text>
            <h6 class="item-name">
              {{ point.name }}
            <!--            <b-card-text class="item-company">-->
            <!--              By <b-link class="ml-25">-->
            <!--                {{ point.brand }}-->
            <!--              </b-link>-->
            <!--            </b-card-text>-->
            </h6>
            <b-card-text class="item-description">
              {{ point.description }}
            </b-card-text>
          </b-card-text>

          <!--    Product Actions    -->
          <!--          <div class="item-options text-center">-->
          <!--            <div>-->
          <!--              <b-avatar-->
          <!--                v-if="'events' in point && point.events.length>0"-->
          <!--                variant="primary"-->
          <!--                :badge="point.events.length.toString()"-->
          <!--                :badge-variant="point.events.findIndex(event=>event.accept_date)!==-1?'success':'dark'"-->
          <!--              >-->
          <!--                <span class="d-flex align-items-center">-->
          <!--                  <feather-icon-->
          <!--                    icon="ToolIcon"-->
          <!--                    size="16"-->
          <!--                  />-->
          <!--                </span>-->
          <!--              </b-avatar>-->
          <!--            </div>-->
          <!--          <div class="item-wrapper">-->
          <!--            <div class="item-cost">-->
          <!--              <h4 class="item-price">-->
          <!--                ${{ point.price }}-->
          <!--              </h4>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <b-button-->
          <!--            variant="light"-->
          <!--            tag="a"-->
          <!--            class="btn-wishlist"-->
          <!--            @click="toggleProductInWishlist(point)"-->
          <!--          >-->
          <!--            <feather-icon-->
          <!--              icon="HeartIcon"-->
          <!--              class="mr-50"-->
          <!--              :class="{'text-danger': point.isInWishlist}"-->
          <!--            />-->
          <!--            <span>Wishlist</span>-->
          <!--          </b-button>-->

          <!--            <b-button-->
          <!--              v-if="!point.events.length>0"-->
          <!--              v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
          <!--              variant="primary"-->
          <!--              class="btn-wishlist"-->
          <!--              :to="{name: 'apps-search-service-request-details', params: { id: point.id }}"-->
          <!--            >-->
          <!--              <feather-icon-->
          <!--                icon="FileTextIcon"-->
          <!--                class="mr-25"-->
          <!--              />-->
          <!--              <span>{{ $t('View') }}</span>-->
          <!--            </b-button>-->
          <!--          </div>-->
        </b-card>
      </section>

      <!-- Pagination -->
      <section v-if="totalPoints">
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="filters.page"
              :total-rows="totalPoints"
              :per-page="filters.perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
    </b-overlay>
    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <left-filter-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import {
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BCardText,
  BPagination,
  BBadge,
  BImgLazy,
  BFormGroup,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import vSelect from 'vue-select'
import Point from '@/mixins/classes/point'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import { getPostStatusProperty, getUserAvatarText } from '@/mixins/functions'
import { usePointsUi } from '@/views/apps/service/usePoints'
import { formatDateTime } from '@/filters/dateTime'
import {
  serviceOptions,
} from '@/mixins/options'
import store from '@/store'
import LeftFilterSidebar from './RequestListLeftFilterSidebar.vue'
import { useShopFiltersSortingAndPagination, useShopUi, useShopRemoteData } from './usePointList'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BCardText,
    BPagination,
    BBadge,
    BImgLazy,
    BFormGroup,
    BOverlay,

    // SFC
    LeftFilterSidebar,

    vSelect,
  },
  filters: {
    formatDateTime,
  },
  mixins: [getPostStatusProperty, getUserAvatarText],
  setup() {
    const { getPurposeTitle, getTypeTitle } = serviceOptions
    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination()

    const {
      handleCartActionClick,
      toggleProductInWishlist,
      handleEditRequestActionClick,
      resolvePointTypeBadgeVariant,
      getFirstGalleryImage,
      defaultImage,
      getOwnerProperty,
    } = usePointsUi()

    const {
      itemView, itemViewOptions, totalPoints,
    } = useShopUi()

    const user = store.getters['account/user']

    const ability = defineAbilityForCurrentUser()

    const {
      points, fetchSearchServiceRequests, submittingFetch,
    } = useShopRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    // Wrapper Function for `fetchSearchServiceRequests` which can be triggered initially and upon changes of filters
    const fetchFilteredPoints = () => {
      const args = {
        q: filters.value.q,
        page: filters.value.page,
        per_page: filters.value.perPage,
      }
      if (filters.value.pointPurposes.length !== 0) {
        args.purpose_ids = filters.value.pointPurposes.join()
      }

      if (sortBy.value.value) {
        args.sort_by = sortBy.value.value
      }

      if (filters.value.postStatuses.length !== 0) {
        args.post_statuses = filters.value.postStatuses.join()
      }
      args.order = 'desc'

      fetchSearchServiceRequests(args)
        .then(response => {
          const { total } = response.data.meta
          points.value = response.data.data.map($item => new Point($item))
          totalPoints.value = total
        })
    }
    fetchFilteredPoints()

    watch([filters, sortBy], () => {
      fetchFilteredPoints()
    }, {
      deep: true,
    })

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalPoints,

      // usePointsUi
      toggleProductInWishlist,
      handleCartActionClick,
      handleEditRequestActionClick,
      resolvePointTypeBadgeVariant,
      getFirstGalleryImage,
      defaultImage,
      getPurposeTitle,
      getTypeTitle,
      getOwnerProperty,

      // useShopRemoteData
      submittingFetch,
      points,
      ability,
      user,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.statuses{
  top: -1px!important;
  right:0;
}
.statuses>span{
  border-radius: 0;
  margin-right: 0!important;
}
.statuses>span:first-child:last-child {
  border-radius:  0 0.428rem 0 0.428rem;
}
.statuses>span:first-child {
  border-radius:  0 0 0 0.428rem;
}
.statuses>span:last-child {
  border-radius:  0 0.428rem 0 0 ;
}
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.search-point.form-control{
  height: auto;
}
[dir] .ecommerce-application .grid-view .ecommerce-card .item-img {
  padding-top: 0;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
  min-height: 15.85rem;
  display: block;
  align-items: center;
}
.card-img-top {
  max-height: 20em;
  object-fit: cover;
}
.dark-layout .ecommerce-application .ecommerce-card .btn-light {
  background-color: #242B3D !important;
}
.list-view .ecommerce-card.no-image  {
  grid-template-columns: 6fr 2fr;
}
.link{
  cursor: pointer;
}

.ecommerce-application .list-view .ecommerce-card .card-body {
  border-right: none;
}
.ecommerce-application .list-view .ecommerce-card{
  margin-bottom: 1rem;
}
@media (max-width: 767.98px) {
  .ecommerce-application .list-view .ecommerce-card.no-image {
    grid-template-columns: 1fr;
  }
}
</style>
